const bazaforCalculation = {
  theme: {
    aterskmaster: 1,
    antikrupr: 1.3,
    astronom: 1,
    banki: 1.3,
    bozopgizni: 1,
    bibliotech: 1,
    biologi: 1,
    veterinar: 1,
    vnesneekonom: 1.3,
    vodniresursi: 1,
    geografi: 1,
    gostbizness: 1.3,
    gosmunuprav: 1.3,
    money: 1.3,
    docsarxiv: 1,
    estestvoznan: 1,
    invest: 1.3,
    innovacmenedg: 1.3,
    iskustvo: 1,
    history: 1,
    konfliktilogi: 1,
    kraeved: 1,
    kulinar: 1,
    literat: 1,
    logika: 1,
    logistic: 1.3,
    marketing: 1.3,
    medicina: 1,
    megdunarotn: 1,
    menedgment: 1.3,
    menedgmentorg: 1.3,
    makroekonom: 1.3,
    music: 1,
    nalogi: 1.3,
    orgrazv: 1.3,
    parikmaher: 1,
    pedagogika: 1,
    politologi: 1,
    pravo: 1.3,
    proizvmarketing: 1.3,
    psihologi: 1,
    reklama: 1.3,
    religia: 1.3,
    // ----------------------------
    cenniebumagi: 1.3,
    sviazsobshestv: 1.3,
    socialwork: 1.3,
    sociologi: 1.3,
    standartiz: 1.3,
    strahovanie: 1.3,
    tamognia: 1.3,
    teoriaupravl: 1.3,
    texnproduktitovari: 1.3,
    tovaroved: 1.3,
    torgovlia: 1.3,
    turism: 1.3,
    upravlkach: 1.3,
    upravlpers: 1.3,
    fizkultura: 1,
    filosifia: 1,
    finmenedgment: 1.3,
    finkredit: 1.3,
    cenoobr: 1.3,
    ekologi: 1.3,
    ekonomika: 1.3,
    etika: 1,
  },
  typeofWork: {
    buzinessplan: 1.5,
    diplomna: 1.6,
    doklad: 1,
    kontrolnai: 1.1,
    kopirait: 1,
    kursova: 1.5,
    magisterska: 2,
    monografia: 1.5,
    otveti: 1,
    otchet: 1.5,
    prezentacia: 1.1,
    referat: 1.1,
    recenz: 1.5,
    sochinen: 1,
    statia: 1.4,
    statiavak: 1.6,
    tvorchwork: 1.4,
    esse: 1,
  },
  timeLimit: {
    1: 1.8,
    "2_3": 1.4,
    "4_5": 1.1,
    6: 1,
  },
  unikalnost: {
    none: 1,
    morethen25: 1.1,
    morethen50: 1.3,
    morethen75: 1.7,
  },
  onelistCost: 10,
};

export default function CalculatCost(themeR, typeofWorkR, timeLimitR, unikalnostR, numlistsR) {
  let cost =
    numlistsR *
    bazaforCalculation.onelistCost *
    bazaforCalculation.theme[themeR] *
    bazaforCalculation.typeofWork[typeofWorkR] *
    bazaforCalculation.timeLimit[timeLimitR] *
    bazaforCalculation.unikalnost[unikalnostR];
  return Math.round(cost);
}
