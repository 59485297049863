import "./guarantees.css";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

function GuaranteesRU() {
  return (
    <main>
      <Helmet>
        <meta name="keywords" content="гарантії" />
        <meta name="description" content="інформація про гарантії, що надаються" />
      </Helmet>

      <Fade cascade damping={0.2} duration={1200} triggerOnce={true} direction="up">
        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантії безпеки коштів:</h4>
          <ul>
            <li className="mb-2">Сервіс гарантує збереження грошей при оплаті роботи.</li>
            <li className="mb-2">Оплата проводиться в розмірі 100% одночасно.</li>
            <li className="mb-2">
              У момент оплати замовлення гроші заморожуються на рахунку та списуються лише після
              закінчення гарантійного терміну на отриману Вами готову роботу.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантії якості роботи:</h4>
          <ul>
            <li className="mb-2">
              Якість роботи вимірюється шляхом зіставлення та перевірки відповідності виконаної
              роботи початковим вимогам Замовника (обсяг, оформлення, рівень оригінальності,
              термінів виконання та інших умов, погоджених перед початком роботи).
            </li>
            <li className="mb-2">
              Якщо робота виконана з порушенням цих вимог, Ви можете відправити її на безкоштовне
              доопрацювання.
            </li>
            <li className="mb-2">
              Якщо проблему не вдається вирішити, можна звернутися із запитом на повернення грошей
              або частковий перерахунок.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантійний термін:</h4>
          <ul>
            <li className="mb-2">
              Гарантія на такі роботи як реферат, стаття, есе та інші роботи невеликого обсягу
              складає 10 днів.
            </li>
            <li className="mb-2">Гарантія на курсові роботи складає 20 днів.</li>
            <li className="mb-2">
              Гарантійний термін на дипломні, магістерські та інші випускні кваліфікаційні роботи
              складає 1 місяць.
            </li>
            <li className="mb-2">
              Протягом цього терміну Ви маєте право звернутися за доопрацюванням, у разі порушення
              заявлених Вами вимог.
            </li>
            <li className="mb-2">
              У деяких випадках гарантійний термін може бути продовжений за рішенням адміністрації
              Сервісу.
            </li>
          </ul>
        </div>
      </Fade>
    </main>
  );
}

export default GuaranteesRU;
