import "./guarantees.css";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

function GuaranteesRU() {
  return (
    <main>
      <Helmet>
        <meta name="keywords" content="гарантии" />
        <meta name="description" content="информация по предоставляемым гарантиям" />
      </Helmet>

      <Fade cascade damping={0.2} duration={1200} triggerOnce={true} direction="up">
        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантии сохранности средств:</h4>
          <ul>
            <li className="mb-2">Сервис гарантирует сохранность ваших денег при оплате работы.</li>
            <li className="mb-2">Оплата производится в размере 100% сразу.</li>
            <li className="mb-2">
              В момент оплаты заказа деньги замораживаются на счету и списываются только после
              истечения гарантийного срока на полученную Вами готовую работу.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантии качества работы:</h4>
          <ul>
            <li className="mb-2">
              Качество работы измеряется путем сопоставления и проверки соответствия выполненной
              работы изначальным требованиям Заказчика (объем, оформление, уровень оригинальности,
              сроков выполнения и иных условий, согласованных перед началом работы).
            </li>
            <li className="mb-2">
              В случае, если работа выполнена с нарушением этих требований, Вы можете отправить ее
              на бесплатную доработку.
            </li>
            <li className="mb-2">
              Если проблему не удается решить, можно обратиться с запросом на возврат денег или
              частичный перерасчет.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-5 mb-4 px-2">Гарантийный срок:</h4>
          <ul className="mb-5">
            <li className="mb-2">
              Гарантия на такие работы как реферат, статья, эссе и иные работы небольшого объема
              составляет 10 дней.
            </li>
            <li className="mb-2">Гарантия на курсовые работы составляет 20 дней.</li>
            <li className="mb-2">
              Гарантийный срок на дипломные, магистерские и другие выпускные квалификационные работы
              составляет 1 месяц.
            </li>
            <li className="mb-2">
              В течении это срока Вы вправе обратиться за доработкой, в случае нарушения заявленных
              Вами требований.
            </li>
            <li className="mb-2">
              В некоторых случаях гарантийный срок может быть продлен по решению администрации
              Сервиса.
            </li>
          </ul>
        </div>
      </Fade>
    </main>
  );
}

export default GuaranteesRU;
